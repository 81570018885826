import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { SubscriptionCenterModule } from './subscription-center/subscription-center.module';
import { CovidComponent } from './covid/covid.component';
import { LoginComponent } from './login/login.component';
import { BigdataComponent } from './bigdata/bigdata.component';
import { LandingTallercontrolModule } from './landingtallercontrol/landingtallercontrol.module';
import { AnalisisDatosComponent } from './analisisdatos/analisisdatos.component';
import { InsightsModule} from './insights/insights.module';
import { NewsletterModule} from './newsletter/newsletter.module';
import { WsComponent} from './ws/ws.component';
import { AboutComponent} from './about/about.component';
import { InteligenciaArtificialImpactoCostaRicaComponent } from './blog/Entry001/InteligenciaArtificialImpactoCostaRica.component';
import { ProcesosAutomatizablesComponent } from './blog/Entry002/ProcesosAutomatizables.component';
import { importanciatransformaciondigitalComponent} from './blog/Entry003/importanciatransformaciondigital.component';
import { medirnecesidaddigitalizarComponent} from './blog/Entry004/medirnecesidaddigitalizar.component';
import { herramientasparadigitalizarComponent} from './blog/Entry005/herramientasparadigitalizar.component';
import { diagnosticosComponent} from './diagnosticos/diagnosticos.component';
import { automatizarComponent} from './automatizar/automatizar.component';
import { ventajasdelanuebaenlaautomatizacionComponent } from './blog/Entry006/ventajasdelanuebaenlaautomatizacion.component';
import { agilidadcomometadetransformacionComponent} from './blog/Entry007/agilidadcomometadetransformacion.component';
import { disminuirerroresautomaticamenteComponent } from './blog/Entry008/disminuirerroresautomaticamente.component';
import { mejorarlaprecisionautomatizandoComponent } from './blog/Entry009/mejorarlaprecisionautomatizando.component';
import { TallerControlModule } from './tallercontrol/tallercontrol.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { MLComponent } from './machinelearning/ml.component';
import { BIComponent } from './bi/bi';
import { TDModule } from './transformacion-digital/td.module';
import { LandingTallerCrecimientoModule } from './landingtallercrecimiento/tc.module';
import { DiagnosticoComponent } from './diagnostico/diagnostico.component';
import { BI2Component } from './bi2/bi2';
import { LoadingInterceptor } from './services/http-interceptor.service';
import { srvAnalyticsComponent } from './srv-analisisdatos/srv-analytics.component';

@NgModule({
   declarations: [
      AppComponent,
      SignupComponent,
      ProfileComponent,
      NavbarComponent,
      FooterComponent,
      LoginComponent,
      BigdataComponent,  
      AnalisisDatosComponent,
      CovidComponent, 
      WsComponent,  
      AboutComponent,
      InteligenciaArtificialImpactoCostaRicaComponent,
      ProcesosAutomatizablesComponent,
      importanciatransformaciondigitalComponent,
      medirnecesidaddigitalizarComponent,
      herramientasparadigitalizarComponent,
      diagnosticosComponent,
      automatizarComponent,
      ventajasdelanuebaenlaautomatizacionComponent,
      agilidadcomometadetransformacionComponent,
      disminuirerroresautomaticamenteComponent,
      mejorarlaprecisionautomatizandoComponent,
      AnalyticsComponent,
      MLComponent,
      BIComponent,
      DiagnosticoComponent,
      BI2Component,
      srvAnalyticsComponent,
      
   // ],
   // schemas: [
   //    CUSTOM_ELEMENTS_SCHEMA
  ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      NgbModule,
      FormsModule,
      RouterModule,
      AppRoutingModule,
      HttpClientModule,
      HomeModule,
      SubscriptionCenterModule,
      InsightsModule,
      NewsletterModule,
      LandingTallercontrolModule,
      LandingTallerCrecimientoModule,
      TallerControlModule,
      TDModule,

      

   ],
   providers: [
      { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
