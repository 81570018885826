import { NgModule } from '@angular/core';
import { LandingTallercontrolComponent } from './landingtallercontrol.component';
import { SectionsModule } from '../sections/sections.module';

@NgModule({
    imports: [SectionsModule],
    declarations: [ LandingTallercontrolComponent ],
    exports:[],
    providers: []
})
export class LandingTallercontrolModule { }
