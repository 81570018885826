export class Subscriber{
    id: number;
    email: string;
    name: string;
    company: string;
    phone: string;
    status: string;
    dateAdd: Date;
    preference: string;
    dateDown: Date;
    reasonDown: string;
}