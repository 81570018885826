import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mq-c',
  templateUrl: './mq-c.component.html',
  styleUrls: ['./mq-c.component.css']
})
export class MarqueeClientesComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
