import { Component, OnInit } from '@angular/core';
import { SubscriptionsService} from '../services/subscriptions.service'
import { Subscriber} from '../models/subscriber'
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';


@Component({
  selector: 'app-form',
  templateUrl: './ws.component.html',
  styleUrls: ['./ws.component.css','./ws.component.scss']
})
export class WsComponent implements OnInit {
  subscriber: Subscriber = new Subscriber();
  success: boolean = false;
  firsttime: boolean = true;
  angForm: FormGroup;
  constructor(private fb: FormBuilder, private subscriptionsService: SubscriptionsService ) { 
    this.createForm();
  }

  createForm() {
    this.angForm = this.fb.group({
       name: [''],
       phone: [''],
       email: ['', Validators.required ],
       company: [''],
       preference: ['']
    }
    )};

  ngOnInit() {
  }

  onSubmit(){
    this.subscriber.status = "Disable";
    this.subscriber.name = "Julio 2020";
    this.subscriber.phone = "072020072020";
    this.subscriber.company = "campaign";
    this.subscriber.preference = "TallerJulio2020";
    this.saveSubscriber();
  }

  
  saveSubscriber(){
    // this.subscriber.dateAdd = new Date();
    this.subscriptionsService.SubscriberCreate(this.subscriber)

        .subscribe(
          data => {
            this.success = true;
            console.log("Form sent! Thank you! ;)");
            //this.subscriber.phone = "";      
            this.subscriber.email = "";
            //this.subscriber.name = "";
           // this.subscriber.company = "";
            this.subscriber.preference = "";
            this.firsttime =false ;
            
          },
          error => console.log("Sorry, cannot be saved!")
        )
  }
}
