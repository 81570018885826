import { NgModule } from '@angular/core';
import { TDComponent } from './td.component';
import { SectionsModule } from '../sections/sections.module';

@NgModule({
    imports: [SectionsModule],
    declarations: [ TDComponent ],
    exports:[],
    providers: []
})
export class TDModule { }
