import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { QuestionService } from '../services/question.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-diagnostico',
  templateUrl: './diagnostico.component.html',
  styleUrls: ['./diagnostico.component.css']
})
export class DiagnosticoComponent implements OnInit {

  public codigo: string = "";
  public questionList: any = [];
  public currentQuestion: number = 0;
  public points: number = 0;
  counterValue: number = 0;
  counter = this.counterValue;
  correctAnswer: number = 0;
  totalQuestions: number = 0;
  totalSections: number = 5;
  inCorrectAnswer: number = 0;
  totalSectionOp: number = 0;
  totalSectionRh: number = 0;
  totalSectionFn: number = 0;
  totalSectionCl: number = 0;
  totalSectionOpq: number = 0;
  totalSectionRhq: number = 0;
  totalSectionFnq: number = 0;
  totalSectionClq: number = 0;
  totalSectionOpa: number = 0;
  totalSectionRha: number = 0;
  totalSectionFna: number = 0;
  totalSectionCla: number = 0;
  totalSectionOpr: string = "DEFICIENTE";
  totalSectionRhr: string = "DEFICIENTE";
  totalSectionFnr: string = "DEFICIENTE";
  totalSectionClr: string = "DEFICIENTE";
  interval$: any;
  progress: string = "0";
  isQuizCompleted : boolean = false;
  constructor(private questionService: QuestionService, private router: Router) { }

  ngOnInit(): void {
    this.codigo = localStorage.getItem("codigo")!;
    if (this.codigo.trim() == null || this.codigo.trim() == 'NOT'){
      this.router.navigate(['/diagnosticos']);
    }else{
      this.getAllQuestions();
      this.startCounter();
    }
   
  }
  
  getAllQuestions() {
    this.questionService.getQuestionJson()
      .subscribe(res => {
        this.questionList = res.questions;
        this.totalQuestions = this.questionList.length+1;
        this.counterValue = this.totalQuestions * 5;
        this.counter =  this.counterValue;
      })
  }

  nextQuestion() {
    if ( this.currentQuestion <= this.totalQuestions){
      this.currentQuestion++;
    }
  }

  previousQuestion() {
    this.currentQuestion--;
  }

  getResults(){

    this.totalSectionOpa = Math.trunc(this.totalSectionOp / this.totalSectionOpq);
    this.totalSectionRha = Math.trunc(this.totalSectionRh / this.totalSectionRhq);
    this.totalSectionFna = Math.trunc(this.totalSectionFn / this.totalSectionFnq);
    this.totalSectionCla = Math.trunc(this.totalSectionCl / this.totalSectionClq);

    switch(this.totalSectionOpa){
      case 4:{
        this.totalSectionOpr = "REGULAR";
        break;
        }
      case 5:{
        this.totalSectionOpr = "BUENO";
        break;
        }
      case 6:{
        this.totalSectionOpr = "OPTIMO";
        break;
        }
    }

    switch(this.totalSectionRha){
      case 4:{
        this.totalSectionRhr = "REGULAR";
        break;
        }
      case 5:{
        this.totalSectionRhr = "BUENO";
        break;
        }
      case 6:{
        this.totalSectionRhr = "OPTIMO";
        break;
        }
    }

    switch(this.totalSectionFna){
      case 4:{
        this.totalSectionFnr = "REGULAR";
        break;
        }
      case 5:{
        this.totalSectionFnr = "BUENO";
        break;
        }
      case 6:{
        this.totalSectionFnr = "OPTIMO";
        break;
        }
    }

    switch(this.totalSectionCla){
      case 4:{
        this.totalSectionClr = "REGULAR";
        break;
        }
      case 5:{
        this.totalSectionClr = "BUENO";
        break;
        }
      case 6:{
        this.totalSectionClr = "OPTIMO";
        break;
        }
    }

  }

  answer(currentQno: number, option: any) {
    

    switch(this.questionList[currentQno].section){
      case "INFORMACIÓN":{
        
        break;
      }
      case "OPERACIONES":{
        this.totalSectionOp += option.valor; 
        this.totalSectionOpq++;
        break;
      }
      case "GESTIÓN DEL RECURSO HUMANO":{
        this.totalSectionRh += option.valor;
        this.totalSectionRhq++;
        break;
      }
      case "CONTABILIDAD Y FINANZAS":{
        this.totalSectionFn += option.valor;
        this.totalSectionFnq++;
        break;
      }
      case "CLIENTES":{
        this.totalSectionCl += option.valor;
        this.totalSectionClq++;
      
        break;
      }
    }

   
    setTimeout(() => {
      this.currentQuestion++;
      this.resetCounter();
      this.getProgressPercent();
    }, 1000);
    
    if(currentQno == this.questionList.length-1){
      this.isQuizCompleted = true;
      this.stopCounter();
      this.getResults();
    }

  }
  
  startCounter() {
    this.interval$ = interval(1000)
      .subscribe(val => {
        this.counter--;
        if (this.counter === 0) {
          this.currentQuestion++;
          this.counter = this.counterValue;
          this.points -= 10;
        }
      });
    setTimeout(() => {
      this.interval$.unsubscribe();
    }, 600000);
  }
  stopCounter() {
    this.interval$.unsubscribe();
    this.counter = 0;
  }
  resetCounter() {
    this.stopCounter();
    this.counter = this.counterValue;
    this.startCounter();
  }
  resetQuiz() {
    this.resetCounter();
    this.getAllQuestions();
    this.points = 0;
    this.counter = this.counterValue;
    this.currentQuestion = 0;
    this.progress = "0";

  }
  getProgressPercent() {
    this.progress = ((this.currentQuestion / this.questionList.length) * 100).toString();
    return this.progress;

  }

}
