import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mejorarlaprecisionautomatizando',
  templateUrl: './mejorarlaprecisionautomatizando.component.html',
  styleUrls: ['./mejorarlaprecisionautomatizando.component.css']
})
export class mejorarlaprecisionautomatizandoComponent implements OnInit {
  focus: any;
  focus1: any;


  
  constructor(private meta: Meta) {
    
  var visitCount = 10; } // Asignar el valor inicial del contador

  
  ngOnInit() {


  }
};
