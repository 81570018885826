/*!

=========================================================
* Plecsys Web Design System 
=========================================================

* Product Page: https://www.plecsys.net
* Copyright 2019 Plecsys Team (https://www.plecsys.net)
* All content was made for Plecsys commercial use. Avoid to copy content from this site now.

* Coded by J.Gondres
* If you have any concern about copyright material send an email to: crm@plecsys.net 
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
