import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tallercontrol',
  templateUrl: './tallercontrol.component.html',
  styleUrls: ['./tallercontrol.component.css']
})
export class TallercontrolComponent implements OnInit {
  focus: any;
  focus1: any;

  images = [
    {title: 'First Slide', desc: 'First Slide Description', src: "./assets/img/theme/banner_tallercontrol_001.png"},
    {title: 'Second Slide', desc: 'Second Slide Description', src: "./assets/img/theme/banner_tallercontrol_002.png"},
    {title: 'Third Slide', desc: 'Third Slide Description', src: "./assets/img/theme/banner_tallercontrol_003.png"}
  ];
  
  constructor(config: NgbCarouselConfig) { 
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationArrows = false;
  }

  ngOnInit() {
  }

}
