import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-InteligenciaArtificialImpactoCostaRica',
  templateUrl: './InteligenciaArtificialImpactoCostaRica.component.html',
  styleUrls: ['./InteligenciaArtificialImpactoCostaRica.component.css']
})
export class InteligenciaArtificialImpactoCostaRicaComponent implements OnInit {
  focus: any;
  focus1: any;
  
  constructor() { }

  ngOnInit() {
  }

}
