import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctc',
  templateUrl: './ctc.component.html',
  styleUrls: ['./ctc.component.css']
})
export class ContentTallerCrecimientoComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
