import { NgModule } from '@angular/core';
import { SectionsModule } from '../sections/sections.module';
import { TallercontrolComponent } from './tallercontrol.component';

@NgModule({
    imports: [SectionsModule],
    declarations: [ TallercontrolComponent ],
    exports:[],
    providers: []
})
export class TallerControlModule { }
