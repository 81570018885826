import { Component, OnInit } from '@angular/core';

let response = "";
@Component({
  selector: 'app-srv-analytics',
  templateUrl: './srv-analytics.component.html',
  styleUrls: ['./srv-analytics.component.css']
})



export class srvAnalyticsComponent implements OnInit {
  constructor( ) {}
  //dia:Diagnostic[];
  ngOnInit() {  
  }

}
