import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { ContactSectionComponent } from './contact-section/contact-section.component';
import { EndingSectionComponent } from './ending-section/ending-section.component'
import { TabsSectionComponent } from './tabs-section/tabs-section.component';
import { WhatwedoSectionComponent } from './whatwedo-section/whatwedo-section.component';
import { NgbdModalComponent } from './modal/modal.component';
import { NgbdModalContent } from './modal/modal.component';
import { SectionsComponent } from './sections.component';
import { BlogEntriesComponent } from './blog-entries/blog-entries.component';
import { PricesTallercontrolComponent } from './pricestallercontrol/pricestallercontrol.component';
import { ContentTallerControlComponent } from './contenttallercontrol/contenttallercontrol.component';
import { InsightsSectionComponent } from './insights-section/insights-section.component';
import { InfoTalleresComponent } from './info-talleres/info-tallerres.component';
import { MarqueeClientesComponent } from './marquee-clientes/mq-c.components';
import { KMComponent } from './km/km.component';
import { InfoTdComponent } from './info-td/info-td.component';
import { IntroTdComponent } from './intro-td/intro-td.component';
import { ContentTallerCrecimientoComponent } from './contenttallercrecimiento/ctc.component';
import { InfoBIComponent } from './info- bi/info-bi.component';


@NgModule({
  declarations: [
    SectionsComponent,
    EndingSectionComponent,
    ContactSectionComponent,
    TabsSectionComponent,
    WhatwedoSectionComponent,
    BlogEntriesComponent,
    NgbdModalComponent,
    NgbdModalContent,
    PricesTallercontrolComponent,
    ContentTallerControlComponent,
    InsightsSectionComponent,
    InfoTalleresComponent,
    MarqueeClientesComponent,
    KMComponent,
    InfoTdComponent,
    IntroTdComponent,
    ContentTallerCrecimientoComponent,
    InfoBIComponent
  ],
  entryComponents: [NgbdModalContent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    NouisliderModule,
    JwBootstrapSwitchNg2Module
  ],
  exports:[ SectionsComponent, BlogEntriesComponent, TabsSectionComponent, 
    InsightsSectionComponent,InfoTalleresComponent,KMComponent, InfoTdComponent,IntroTdComponent,
    PricesTallercontrolComponent, ContentTallerControlComponent,WhatwedoSectionComponent, 
    EndingSectionComponent,MarqueeClientesComponent,ContentTallerCrecimientoComponent,InfoBIComponent ]
})


export class SectionsModule { }
