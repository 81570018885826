import { NgModule } from '@angular/core';
import { LandingTallerCrecimientoComponent } from './tc.component';
import { SectionsModule } from '../sections/sections.module';

@NgModule({
    imports: [SectionsModule],
    declarations: [ LandingTallerCrecimientoComponent ],
    exports:[],
    providers: []
})
export class LandingTallerCrecimientoModule { }
