import { Component, OnInit } from '@angular/core';
import { SubscriptionsService} from '../../services/subscriptions.service'
import { Subscriber} from '../../models/subscriber'
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  subscriber: Subscriber = new Subscriber();
  success: boolean = false;
  firsttime: boolean = true;
  angForm: FormGroup;
  constructor(private fb: FormBuilder, private subscriptionsService: SubscriptionsService ) { 
    this.createForm();
  }

  createForm() {
    this.angForm = this.fb.group({
       name: ['', Validators.required ],
       phone: ['', Validators.required ],
       email: ['', Validators.required ],
       company: ['', Validators.required ]
    }
    )};

  ngOnInit() {
  }

  onSubmit(){
    this.subscriber.status = "Disable";
    this.subscriber.preference = "subscriptionPageForm2";
    this.saveSubscriber();
  }

  
  saveSubscriber(){
    // this.subscriber.dateAdd = new Date();
    this.subscriptionsService.SubscriberCreate(this.subscriber)

        .subscribe(
          data => {
            this.success = true;
            console.log("Form sent! Thank you! ;)");
            this.subscriber.phone = "";      
            this.subscriber.email = "";
            this.subscriber.name = "";
            this.subscriber.company = "";
            this.firsttime =false ;
            
          },
          error => console.log("Sorry, cannot save!")
        )
  }
}
