import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-km',
  templateUrl: './km.component.html',
  styleUrls: ['./km.component.css']
})
export class KMComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
