import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-disminuirerroresautomaticamente',
  templateUrl: './disminuirerroresautomaticamente.component.html',
  styleUrls: ['./disminuirerroresautomaticamente.component.css']
})
export class disminuirerroresautomaticamenteComponent implements OnInit {
  focus: any;
  focus1: any;


  
  constructor(private meta: Meta) {
    
  var visitCount = 10; } // Asignar el valor inicial del contador

  
  ngOnInit() {


  }
};
