import { Component, OnInit } from '@angular/core';
import { CovidUser} from '../models/covidUser'
import { CovidService} from '../services/covid.service'
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import 'rxjs'

@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.css', 
  './covid.component.scss']
})



export class CovidComponent implements OnInit {
  focus;
  focus1;
  covidUser: CovidUser = new CovidUser();
  success: boolean = false;
  angForm: FormGroup;
  constructor(private fb: FormBuilder, private covidService: CovidService ) { 
    this.createForm();
  }

  createForm() {
    this.angForm = this.fb.group({
       userName: ['', Validators.required ],
       userCode: ['', Validators.required ],
       samplingCode: ['', Validators.required ],
       phoneNumber: ['', Validators.required ]
    }
    )};

  ngOnInit() {
    // this.subscriber.dateAdd = new Date();
    // this.subscriber.phone = "2322332";
    //this.covidUser.userName = "jgondres";
    // this.subscriber.preference = "hola";
    // this.subscriber.email = "test@test.com"
    // this.subscriber.name = "Jeffry Gondres"
  }

  onSubmit(){
    this.saveCovidUser();
  }

  saveCovidUser(){
    // this.subscriber.dateAdd = new Date();
    this.covidService.CovidTestCreate(this.covidUser)
          .subscribe(         
          data => {
            this.covidUser.phoneNumber = null;      
            this.covidUser.samplingCode = null;
            this.covidUser.userCode = null;
            this.covidUser.userName = "";    
          },
          error => console.log("Sorry, cannot save!")
        )
        
  }
}
