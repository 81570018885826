import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-td',
  templateUrl: './td.component.html',
  styleUrls: ['./td.component.css'],

})
export class TDComponent implements OnInit {
  focus: any;
  focus1: any;
 
  
  ngOnInit() {
  }



}
