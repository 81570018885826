import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analisisdatos',
  templateUrl: './analisisdatos.component.html',
  styleUrls: ['./analisisdatos.component.css']
})
export class AnalisisDatosComponent implements OnInit {
  focus: any;
  focus1: any;
  
  constructor() { }

  ngOnInit() {
  }

}
