import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'
import { Diagnostic } from '../models/diagnostic'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'responseType': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})

export class PlecsysService {

  //private endpoint = 'https://plecsys-studio.appspot.com/3454545FDFDFSSFDW3RRW3FSDSFD4544/'
  private endpoint = 'https://plecsys-studio-bi.net/api/PlecysWebDiagnostics'
  //private endpoint = 'https://localhost:44340/api/PlecysWebDiagnostics'
  
  constructor(private http : HttpClient) { }
 
//POST - Add nre flight
  DiagnosticCreate(diagnostic: Diagnostic): Observable<object>{
    return this.http.post<any>(this.endpoint, diagnostic, httpOptions);
  }
  
}
