import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SubscriptionCenterComponent } from './subscription-center.component';
import { DashboardComponent} from './dashboard/dashboard.component'
import { FormComponent} from './form/form.component'


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
    // CircleMenuComponent
  ], 
  declarations: [
    SubscriptionCenterComponent,
    DashboardComponent,
    FormComponent
  ]
})
export class SubscriptionCenterModule { }
