import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricestallercontrol',
  templateUrl: './pricestallercontrol.component.html',
  styleUrls: ['./pricestallercontrol.component.css'],

})
export class PricesTallercontrolComponent implements OnInit {
  focus: any;
  focus1: any;
 
  
  ngOnInit() {
  }



}
