import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-td',
  templateUrl: './intro-td.component.html',
  styleUrls: ['./intro-td.component.css']
})
export class IntroTdComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
