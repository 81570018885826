import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-ProcesosAutomatizables',
  templateUrl: './ProcesosAutomatizables.component.html',
  styleUrls: ['./ProcesosAutomatizables.component.css']
})
export class ProcesosAutomatizablesComponent implements OnInit {
  focus: any;
  focus1: any;


  
  constructor(private meta: Meta) {
    
  var visitCount = 10; } // Asignar el valor inicial del contador

  
  ngOnInit() {

    this.meta.addTag({ property: 'og:image', content: 'https://plecsys.net/assets/img/theme/blog002_article.png' });
    this.meta.addTag({ property: 'og:url', content: 'https://plecsys.net/assets/img/theme/blog002_article.png' });
    
  }
};
