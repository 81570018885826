import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ending-section',
  templateUrl: './ending-section.component.html',
  styleUrls: ['./ending-section.component.css']
})
export class EndingSectionComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
