import { Component, OnInit } from '@angular/core';
import AOS from "aos";
@Component({
  selector: 'app-insights-section',
  templateUrl: './insights-section.component.html',
  styleUrls: ['./insights-section.component.css']
})
export class InsightsSectionComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    AOS.init();
  }

}
