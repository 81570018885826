import { Component, OnInit } from '@angular/core';
import { Subscriber} from '../../models/subscriber'
import { SubscriptionsService} from '../../services/subscriptions.service'
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.css', 
  './contact-section.component.scss']
})



export class ContactSectionComponent implements OnInit {
  focus;
  focus1;
  subscriber: Subscriber = new Subscriber();
  success: boolean = false;
  firsttime: boolean = true;
  angForm: FormGroup;
  constructor(private fb: FormBuilder, private subscriptionsService: SubscriptionsService ) { 
    this.createForm();
  }

  createForm() {
    this.angForm = this.fb.group({
       name: ['', Validators.required ],
       phone: ['', Validators.required ],
       preference: ['', Validators.required ],
       email: ['', Validators.required ]
    }
    )};

  ngOnInit() {
    // this.subscriber.dateAdd = new Date();
    // this.subscriber.phone = "2322332";
    this.subscriber.status = "Disable";
    // this.subscriber.preference = "hola";
    // this.subscriber.email = "test@test.com"
    // this.subscriber.name = "Jeffry Gondres"
  }

  onSubmit(){
    this.saveSubscriber();
  }

  saveSubscriber(){
    // this.subscriber.dateAdd = new Date();
    this.subscriptionsService.SubscriberCreate(this.subscriber)

        .subscribe(
          data => {
            this.success = true;
            console.log("Form sent! Thank you! ;)");
            this.subscriber.phone = "";
            this.subscriber.status = "Duplicated";
            this.subscriber.preference = "";
            this.subscriber.email = ""
            this.subscriber.name = ""
            this.firsttime =false ;
           
          },
          error => console.log("Sorry, cannot save!")
        )
  }
}
