import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
// import { SignupComponent } from './signup/signup.component';
//import { LoginComponent } from './login/login.component';
import { BigdataComponent } from './bigdata/bigdata.component';
import { TallercontrolComponent } from './tallercontrol/tallercontrol.component';
import { AnalisisDatosComponent } from './analisisdatos/analisisdatos.component';
import { SubscriptionCenterComponent} from './subscription-center/subscription-center.component';
import { InsightsComponent } from './insights/insights.component';
import { WsComponent } from './ws/ws.component';
//import { AboutComponent } from './about/about.component';
import { InteligenciaArtificialImpactoCostaRicaComponent } from './blog/Entry001/InteligenciaArtificialImpactoCostaRica.component';
import { ProcesosAutomatizablesComponent } from './blog/Entry002/ProcesosAutomatizables.component';
import { importanciatransformaciondigitalComponent } from './blog/Entry003/importanciatransformaciondigital.component';
import { medirnecesidaddigitalizarComponent } from './blog/Entry004/medirnecesidaddigitalizar.component';
import { herramientasparadigitalizarComponent } from './blog/Entry005/herramientasparadigitalizar.component';
import { diagnosticosComponent } from './diagnosticos/diagnosticos.component';
import { automatizarComponent } from './automatizar/automatizar.component';
import { ventajasdelanuebaenlaautomatizacionComponent} from './blog/Entry006/ventajasdelanuebaenlaautomatizacion.component';
import { agilidadcomometadetransformacionComponent} from './blog/Entry007/agilidadcomometadetransformacion.component'; 
import { disminuirerroresautomaticamenteComponent } from './blog/Entry008/disminuirerroresautomaticamente.component';
import { mejorarlaprecisionautomatizandoComponent } from './blog/Entry009/mejorarlaprecisionautomatizando.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { LandingTallercontrolComponent } from './landingtallercontrol/landingtallercontrol.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { MLComponent } from './machinelearning/ml.component';
import { BIComponent } from './bi/bi';
import { TDComponent } from './transformacion-digital/td.component';
import { LandingTallerCrecimientoComponent } from './landingtallercrecimiento/tc.component';
import { DiagnosticoComponent } from './diagnostico/diagnostico.component';
import { BI2Component } from './bi2/bi2';
import { srvAnalyticsComponent } from './srv-analisisdatos/srv-analytics.component';


const routes: Routes =[
    { path: 'home',               component: HomeComponent },
    // { path: 'user-profile',       component: ProfileComponent },
    // { path: 'register',           component: SignupComponent },
    //{ path: 'login',              component: LoginComponent },
    //{ path: 'covid',              component: CovidComponent },
    { path: 'bigdata', component: BigdataComponent },
    { path: 'tallercontrol', component: TallercontrolComponent },
    { path: 'landingtallercontrol', component: LandingTallercontrolComponent },
    { path: 'landingtallercrecimiento', component: LandingTallerCrecimientoComponent },
    { path: 'analisisdatos', component: AnalisisDatosComponent },
    { path: 'InteligenciaArtificialImpactoCostaRica', component: InteligenciaArtificialImpactoCostaRicaComponent},
    { path: 'ProcesosAutomatizables', component: ProcesosAutomatizablesComponent},
    { path: 'importanciatransformaciondigital', component: importanciatransformaciondigitalComponent},
    { path: 'medirnecesidaddigitalizar', component: medirnecesidaddigitalizarComponent},
    { path: 'herramientasparadigitalizar', component: herramientasparadigitalizarComponent},
    { path: 'insights', component: InsightsComponent },
    { path: 'newsletter', component: NewsletterComponent },
    { path: 'subscription-center',component: SubscriptionCenterComponent },
    { path: 'diagnosticos',component: diagnosticosComponent },
    { path: 'automatizar',component: automatizarComponent },
    { path: 'ventajasdelanuebaenlaautomatizacion', component: ventajasdelanuebaenlaautomatizacionComponent},
    { path: 'agilidadcomometadetransformacion', component: agilidadcomometadetransformacionComponent},
    { path: 'disminuirerroresautomaticamente', component: disminuirerroresautomaticamenteComponent},
    { path: 'mejorarlaprecisionautomatizando', component: mejorarlaprecisionautomatizandoComponent},
    { path: 'profile', component: ProfileComponent},
    { path: 'analytics', component: AnalyticsComponent},
    { path: 'machinelearning', component: MLComponent},
    { path: 'businessintelligence', component: BIComponent},
    { path: 'business-intelligence', component: BIComponent},
    { path: 'inteligencia-de-negocios', component: BIComponent},
    { path: 'transformaciondigital', component: TDComponent},
    { path: 'transformacion-digital', component: TDComponent},
    { path: 'diagnostico', component: DiagnosticoComponent},
    { path: 'que-es-inteligencia-de-negocios', component: BI2Component},
    //{ path: 'analisis-de-datos', component: srvAnalyticsComponent},
    { path: 'analisis-de-datos', component: AnalyticsComponent},
    { path: 'ws',component: WsComponent },
    //{ path: 'about',component: AboutComponent },
    { path: '', component: HomeComponent },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
