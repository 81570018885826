import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guiding-topics',
  templateUrl: './guiding-topics.component.html',
  styleUrls: ['./guiding-topics.component.css']
})
export class GuidingTopicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
