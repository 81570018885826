import { NgModule  } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NewsletterComponent } from './newsletter.component';
import { IntroductionComponent} from './introduction/introduction.component'
import { GuidingTopicsComponent } from './guiding-topics/guiding-topics.component'
import { ConsultingComponent} from './consulting/consulting.component'
import {ContactSectionComponent} from './contact-section/contact-section.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,  
    NgbModule,
  ],
  declarations: [
    NewsletterComponent,
    IntroductionComponent,
    GuidingTopicsComponent,
    ConsultingComponent,
    ContactSectionComponent
  ]
})
export class NewsletterModule { }
