import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-blog-entries',
  templateUrl: './blog-entries.component.html',
  styleUrls: ['./blog-entries.component.css']
})
export class BlogEntriesComponent implements OnInit {

  posts = [
    { postid: "1", title: 'Agilidad y transformación ',  date: '02-Agosto-2023',content: 'Cómo utilizar la automatización para agilizar la gestión de inventario...', image: './assets/img/theme/blog007.png', id: '/agilidadcomometadetransformacion' },
    { postid: "2", title: 'Ventajas de la nube ',  date: '19-Julio-2023',content: 'Ventajas de la nueba en la automatización, tiene que ver con aspectos más allá de ...', image: './assets/img/theme/blog006.png', id: '/ventajasdelanuebaenlaautomatizacion' },
    { postid: "3", title: 'Herramientas para digitalizar',  date: '05-Julio-2023',content: 'La gestión eficiente del inventario es un desafío fundamental para las ...', image: './assets/img/theme/blog004.png', id: '/importanciatransformaciondigital' },
    
  ];
  constructor() { }

  ngOnInit() {
    AOS.init();
  }

}
