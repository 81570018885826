import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'
import { Subscriber } from '../models/subscriber'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('W2019Pl2CSYS.1024:s5B3vx^brfR7DKV-5Z_m+w')
  })
};


@Injectable({
  providedIn: 'root'
})

export class SubscriptionsService {

  //private endpoint = 'https://plecsys-studio.appspot.com/3454545FDFDFSSFDW3RRW3FSDSFD4544/'
  private endpoint = 'https://plecsys-studio.appspot.com/subscribers/'
  
  constructor(private http : HttpClient) { }
 
//POST - Add nre flight
  SubscriberCreate(subscriber: Subscriber): Observable<object>{
    return this.http.post(this.endpoint, subscriber, httpOptions);
  }
  
}
