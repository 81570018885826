import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-center',
  templateUrl: './subscription-center.component.html',
  styleUrls: ['./subscription-center.component.css']
})

export class SubscriptionCenterComponent implements OnInit {

  constructor( ) {}


  ngOnInit() {
  }


}
