import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-automatizar',
  templateUrl: './automatizar.component.html',
  styleUrls: ['./automatizar.component.css']
})
export class automatizarComponent implements OnInit {
  focus: any;
  focus1: any;
  
  constructor() { }

  ngOnInit() {
  }

}
