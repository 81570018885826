import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'
import { CovidUser } from '../models/covidUser'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'x-api-key' : 'wY3U99ChkX3Bm63Dh0Skb7BSmeYUdNrI6k5yygaW'
  })
};

@Injectable({
  providedIn: 'root'
})

export class CovidService {
  private endpoint = 'https://5v4pavgut8.execute-api.us-east-2.amazonaws.com/v1/send-sample'
    constructor(private http : HttpClient) { }
 
//POST - Add nre flight
  CovidTestCreate(covidUser: CovidUser): Observable<object>{
    return this.http.post(this.endpoint, covidUser, httpOptions);
  }
}
