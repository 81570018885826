import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-td',
  templateUrl: './info-td.component.html',
  styleUrls: ['./info-td.component.css']
})
export class InfoTdComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
