import { Component, OnInit, Inject, ElementRef, ViewChild, HostListener, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';

import { LocationStrategy, PlatformLocation, Location, DOCUMENT } from '@angular/common';


 
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
    private _router: Subscription;

    constructor( private renderer : Renderer2, private router: Router, @Inject(DOCUMENT,) 
    private document: any, private element : ElementRef, public location: Location) {
        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //       (<any>window).ga('set', 'page', event.urlAfterRedirects);
        //       (<any>window).ga('send', 'pageview');
        //     }
        //   });
    }
    
    @HostListener('window:scroll', ['$event'])
    hasScrolled() {

        var st = window.pageYOffset;
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        var navbar = document.getElementsByTagName('nav')[0];

        // If they scrolled down and are past the navbar, add class .headroom--unpinned.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            if (navbar.classList.contains('headroom--pinned')) {
                navbar.classList.remove('headroom--pinned');
                navbar.classList.add('headroom--unpinned');
            }
            // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
        } else {
            // Scroll Up
            //  $(window).height()
            if(st + window.innerHeight < document.body.scrollHeight) {
                // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
                if (navbar.classList.contains('headroom--unpinned')) {
                    navbar.classList.remove('headroom--unpinned');
                    navbar.classList.add('headroom--pinned');
                }
            }
        }

        lastScrollTop = st;
    };
    ngOnInit() {
//Google Analytics
   
      var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          if (window.outerWidth > 991) {
              window.document.children[0].scrollTop = 0;
          }else{
              window.document.activeElement.scrollTop = 0;
          }
          this.renderer.listen('window', 'scroll', (event) => {
              const number = window.scrollY;
              if (number > 150 || window.scrollY > 150) {
                  // add logic
                  navbar.classList.add('headroom--not-top');
              } else {
                  // remove logic
                  navbar.classList.remove('headroom--not-top');
              }
          });
      });

    //    this.getUniqueVisits().then((uniqueVisits: number) => {
    //     console.log('Número de visitas únicas:', uniqueVisits);
    // });
    // }

    // getUniqueVisits(): Promise<number> {
    //     return new Promise((resolve) => {
    //         let ga = window.ga; 
    //       if (window.ga) {
    //         window.ga(function () {
    //           const tracker = window.ga.getByName('t0'); // Obtén el tracker predeterminado
    //           const clientId = tracker.get('clientId'); // Obtén el ID de cliente
      
    //           resolve(clientId ? 1 : 0); // Si se obtuvo el ID de cliente, se considera una visita única
    //         });
    //       } else {
    //         resolve(0); // Google Analytics no está cargado
    //       }
    //     });

      this.hasScrolled();
    }
}
