import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { PlecsysService} from '../services/plecsys.service'
import { Diagnostic } from '../models/diagnostic';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader';

let response = "";
@Component({
  selector: 'app-diagnosticos',
  templateUrl: './diagnosticos.component.html',
  styleUrls: ['./diagnosticos.component.scss']
})



export class diagnosticosComponent implements OnInit {

  @ViewChild('email' , { static: false }) emailKey: ElementRef;
  @ViewChild('codigo' , { static: false }) codigoKey: ElementRef;
  constructor(private plecsysService:PlecsysService, private router: Router,
    public loader: LoaderService ) {}



  //dia:Diagnostic[];
  diagnostic = new Diagnostic();
  codec: String;
  ngOnInit() {
    localStorage.setItem("codigo",'NOT');

  }


  startDiagnostico(){
    localStorage.setItem("codigo",this.codigoKey.nativeElement.value);

    this.codec = localStorage.getItem("codec");

    if (this.codec ==this.codigoKey.nativeElement.value){
      this.router.navigate(['/diagnostico']);
    }
    }
    startFlow(){
      this.codec = 'NOT';

      this.diagnostic.email =this.emailKey.nativeElement.value;
      this.diagnostic.code ="";
      this.diagnostic.result = "";
      this.plecsysService.DiagnosticCreate(this.diagnostic)
      .subscribe((data) => { // Success
        response = JSON.stringify(data).replace(/['"]+/g, '');
        if (response.toString() != 'NOT'){
          localStorage.setItem("codec",response);
          //this.diagnostic.code = "344PLECS203";
          //this.plecsysService.DiagnosticCreate(this.diagnostic).subscribe();
          alert("Código enviado");
        }else{
          localStorage.setItem("codec",'NOT');
          alert("Usuario ya registrado");      
        }
      },
        (error) => {
          //console.error("Error: " ,JSON.stringify(error));
        });
    }
  }
