import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-talleres',
  templateUrl: './info-talleres.component.html',
  styleUrls: ['./info-talleres.component.css']
})
export class InfoTalleresComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
