import { Component,  OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-info-bi',
  templateUrl: './info-bi.component.html',
  styleUrls: ['./info-bi.component.css'],
 
})
export class InfoBIComponent implements OnInit{
  
  constructor() { }
 
  ngOnInit(): void {
    AOS.init();
  }
  

}
